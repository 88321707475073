import { Link } from "gatsby";
import PropTypes from "prop-types";
import React from "react";

const Header = ({ siteTitle }) => (
    <header className="site-header px2 px-responsive">
        <div className="mt2 wrap">
            <div className="measure">
                <Link to={`/`} className="site-title">
                    {siteTitle}
                </Link>

                <nav className="site-nav">
                    <Link to="/about">About</Link>
                </nav>

                <div className="clearfix" />
            </div>
        </div>
    </header>
);

Header.propTypes = {
    siteTitle: PropTypes.string
};

Header.defaultProps = {
    siteTitle: ``
};

export default Header;
