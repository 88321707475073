/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";

import Header from "./header";
import "../css/index.css";
import "../css/katex.css";

const Layout = ({ children }) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `);

    return (
        <div className={`site animated fade-in-down`}>
            <Header siteTitle={data.site.siteMetadata.title} />

            <div
                style={{
                    margin: `0 auto`,
                    maxWidth: 960,
                    padding: `0 1.0875rem 1.45rem`
                }}>
                <main>{children}</main>
            </div>

            <footer className="center">
                <div className="measure">
                    <small>
                        © {new Date().getFullYear()} | A blog by{" "}
                        <a
                            href="http://designedbywaldo.com"
                            target="_blank"
                            rel="noopener noreferrer">
                            Ifor Waldo Williams
                        </a>{" "}
                        (
                        <a
                            href="https://twitter.com/iforwms"
                            target="_blank"
                            rel="noopener noreferrer">
                            @iforwms
                        </a>
                        ).
                        <br />
                        &lt;/&gt; available on{" "}
                        <a
                            href="https://github.com/iforwms"
                            target="_blank"
                            rel="noopener noreferrer">
                            Github
                        </a>
                        .
                    </small>
                </div>
            </footer>
        </div>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired
};

export default Layout;
